import { buildLocale, localeRouteInterface } from "./MarketandLanguage";
import { DEFAULT_PAGE, APP_SITE } from "../config/environment";

// A function to build URLs throughout the entire application
// This keeps the URL building consistent and allows for global manipulation
// Also automatically adds the locale if the pageArgs object is passed in
export const buildUrl = (
	href: string | undefined,
	pageArgs: localeRouteInterface | null = null,
	skipLocale: boolean = false
): string => {
	if (isFullUrl(href)) {
		return href;
	}
	// Remove beginning slash if passed
	href = href?.startsWith("/") ? href.substring(1) : href;

	let prefix = "";
	if (pageArgs && !skipLocale) {
		// buildLocale will return either `{market}` or `{market}-{lang}` depending on the situation
		prefix += `${buildLocale(pageArgs)}/`;
	}

	return `/${prefix}${href || DEFAULT_PAGE}`;
};

// Checks if a url is relative or has a base (`example.com/test`, `/test`, `test`)
export function isFullUrl(url: string): boolean {
	// Regular expression to check if the URL starts with a protocol (http://, https://, ftp://, etc.) or is a relative path
	const urlRegex = /^(?:(?:https?|ftp):\/\/|\/)[^\s/$.?#].[^\s]*$/i;
	return urlRegex.test(url);
}
