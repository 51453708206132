"use client";
import { datadogRum } from "@datadog/browser-rum";
import { APP_ENV, APP_SITE } from "../../config";

const DD_DETAILS = (() => {
	switch (APP_SITE) {
		case "jobs":
			return {
                service: "web-recruitment",
                application_id: "e0296e5f-527c-4dd6-8edd-2a9795a081bc",
                client_token: "pubdd57ff759c96ebbf0e9357ccd68dcfe2"
            };
		case "consumer":
			return {
                service: null,
                application_id: null,
                client_token: null
            };
		default:
			return {
                service: null,
                application_id: null,
                client_token: null
            };
	}
})();

export const DD_APPLICATION_ID = DD_DETAILS["application_id"];
export const DD_CLIENT_TOKEN = DD_DETAILS["client_token"];
export const DD_REPLAY_SAMPLE_RATE = 0; // TODO: propose adding to contract
export const DD_SAMPLE_RATE = Number.isNaN(parseInt(process.env["NEXT_PUBLIC_DD_SAMPLE_RATE"])) ? 1 : parseInt(process.env["NEXT_PUBLIC_DD_SAMPLE_RATE"]);
export const DD_SERVICE = DD_DETAILS["service"];

// Only allow on development and production
// TODO: Disable development when POC complete
if(["production"].includes(process.env["APP_ENV"])) {
	// https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/
	datadogRum.init({
		applicationId: DD_APPLICATION_ID,
		clientToken: DD_CLIENT_TOKEN,
		site: "datadoghq.eu",
		service: DD_SERVICE,
		env: APP_ENV,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: process.env.APP_VERSION,
		sessionSampleRate: DD_SAMPLE_RATE,
		sessionReplaySampleRate: DD_REPLAY_SAMPLE_RATE,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
		enableExperimentalFeatures: ["feature_flags"],
	});
}

export default function DatadogInit() {
	// Render nothing - this component is only included so that the init code
	// above will run client-side
	return null;
}
