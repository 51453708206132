import { getEnvVar } from "./environment";

export const FOUNTAIN_API_KEY = getEnvVar(
	"FOUNTAIN_API_KEY",
	process.env.NEXT_PUBLIC_FOUNTAIN_API_KEY,
	""
);
export const GREENHOUSE_JOBBOARD_KEY = getEnvVar(
	"GREENHOUSE_JOBBOARD_KEY",
	process.env.NEXT_PUBLIC_GREENHOUSE_JOBBOARD_KEY,
	""
);

export const INDEED_API_SECRET = getEnvVar("INDEED_API_SECRET", null, "");
