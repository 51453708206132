import { getEnvVar } from "./environment";

export const APP_ENV = getEnvVar(
	"APP_ENV",
	process.env.NEXT_PUBLIC_APP_ENV,
	"local"
);
export const API_URL = getEnvVar(
	"API_URL",
	process.env.NEXT_PUBLIC_API_URL,
	"https://web-utilities-prod.global.picnicinternational.com"
);
export const APP_API_KEY = getEnvVar(
	"APP_API_KEY",
	process.env.NEXT_PUBLIC_API_KEY,
	""
);
export const LOCAL_API_URL = "http://127.0.0.1:3005";
export const LEGACY_WORDPRESS_URL = getEnvVar(
	"LEGACY_WORDPRESS_URL",
	process.env.NEXT_PUBLIC_LEGACY_WORDPRESS_URL,
	"https://picnic.app"
);
export const LEGACY_WORDPRESS_SITE = getEnvVar(
	"LEGACY_WORDPRESS_SITE",
	process.env.NEXT_PUBLIC_LEGACY_WORDPRESS_SITE,
	"legacy-careers"
);
export const BUILD_STATIC = APP_ENV != "local";
export const ASSETS_DIR = "assets";
export const ASSETS_URL = "https://d2jxuf8ovdiw8x.cloudfront.net";

export const AWS_KEY_ID = process.env["AWS_KEY_ID"]
	? process.env["AWS_KEY_ID"]
	: "";
export const AWS_SECRET = process.env["AWS_SECRET"]
	? process.env["AWS_SECRET"]
	: "";

export const GOOGLE_API_KEY = getEnvVar(
	"GOOGLE_API_KEY",
	process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
	""
);
export const EVENTS_SPREADSHEET = getEnvVar(
	"EVENTS_SPREADSHEET",
	process.env.NEXT_PUBLIC_EVENTS_SPREADSHEET,
	""
);

export const UTILITY_ROUTES = ["/mng/health"];
