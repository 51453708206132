import { PHASE_PRODUCTION_BUILD } from "next/dist/shared/lib/constants";
import { APP_SITE, GLOBAL_PAGE_SITES, JOB_TERMS } from "../config/environment";
import { buildUrl } from "@/utils/buildUrl";
import Image from "next/image";

export interface localeRouteInterface {
	locale: {
		market: string;
		language: string;
	};
	route: string[];
	query: any;
	preview?: boolean;
	application: string;
	buildStage?: boolean;
	extra?: any;
}

export const parsePathToApplication = (
	pathArray: string[],
	query: any = {},
	buildStage: boolean = false,
	currentLocale: string | null = null,
	draftMode: boolean = false
): localeRouteInterface => {
	const application = APP_SITE || "mono";
	pathArray = Array.isArray(pathArray) ? pathArray : [];
	if (pathArray.length > 0 && pathArray[0] == "") {
		pathArray.splice(0, 1);
	}
	let defaultLocale = GLOBAL_PAGE_SITES.includes(application)
		? "global-nl"
		: "nl-nl";
	// Set default values
	let locale = currentLocale || defaultLocale;
	let defaultRoute = ["home"];
	// const proxyGeolocation = headersList.get('x-vercel-ip-country');

	// Check if the first element is a valid locale
	if (
		pathArray.length > 0 &&
		/^[a-z]{2}(-[a-zA-Z]{2})?$/.test(pathArray[0])
	) {
		if (GLOBAL_PAGE_SITES.includes(application)) {
			locale = "global-" + pathArray[0];
			pathArray.shift(); // Remove the locale from the array
		} else {
			locale = pathArray[0];
			pathArray.shift(); // Remove the locale from the array
		}
	}

	if (pathArray.length == 0) {
		pathArray = defaultRoute;
	}

	return {
		locale: getMarketandLang(locale),
		route: pathArray,
		query: query,
		application: APP_SITE,
		buildStage: buildStage,
		preview: draftMode
	};
};

export const buildLocaleRoute = (pageArgs: localeRouteInterface) => {
	return `/${buildLocale(pageArgs)}/${pageArgs.route.join("/")}`;
};
export const buildLocale = (pageArgs: localeRouteInterface) => {
	return pageArgs.locale.language;
};

export const getMarketandLang = (locale: string) => {
	const markets = ["nl", "de", "fr"];
	// const locales = ["nl", "de", "fr, en"];
	const splitLocale = locale ? locale.split("-") : markets[0];
	return {
		market: splitLocale[0],
		language: splitLocale[1] ? splitLocale[1] : splitLocale[0],
	};
};

export const newLocaleURL = (market, language, pageArgs) => {
	const newPageArgs = pageArgs;
	newPageArgs.locale.market = market;
	newPageArgs.locale.language = language;
	if (
		JOB_TERMS.includes(newPageArgs.route[0]) &&
		newPageArgs.route.length > 1 &&
		newPageArgs.route[1].length == 7
	) {
		newPageArgs.route[1] = `J${newPageArgs.route[1]}`;
	}
	return buildUrl(newPageArgs.route.join("/"), newPageArgs);
};

export const countryCodeImage = {
	en: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg",
	de: "https://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg",
	fr: "https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg",
	nl: "https://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg",
};
export const getFlagEmoji = (countryCode) => {
	return (
		<Image
			className="my-auto"
			src={countryCodeImage[countryCode] || ""}
			alt={`${countryCode.toUpperCase()} flag`}
			width={20}
			height={20}
		/>
	);
};

export const secondaryLangHeaderBtn = (translations, args) => {
	if (translations) {
		const enabledTranslations = Object.keys(translations).filter(
			(key) => translations[key]
		);

		if (enabledTranslations.length == 2) {
			const langToChange =
				enabledTranslations[0] == args.locale.language
					? enabledTranslations[1]
					: enabledTranslations[0];

			const btnText = `<b>${langToChange.toUpperCase()}</b>`;

			return {
				text: btnText,
				link: newLocaleURL(args.locale.language, langToChange, args),
				icon: countryCodeImage[langToChange],
				target: "_self",
				styling: {
					sizing: "xs",
					background_color: "white",
					rounded: false,
					text_color: "grey5",
				},
			};
		}
	}
	return null;
};
