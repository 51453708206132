"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";

const pageview = () => {
	window.fbq("track", "PageView");
};

const event = (name, options = {}) => {
	window.fbq("track", name, options);
};

const FacebookPixel = () => {
	const [loaded, setLoaded] = useState(false);
	const pathname = usePathname();

	useEffect(() => {
		if (!loaded) return;

		pageview();
	}, [pathname, loaded]);

	return (
		<div>
			<Script
				id="fb-pixel"
				strategy="afterInteractive"
				onLoad={() => setLoaded(true)}
				data-pixel-id="835613110216090"
				dangerouslySetInnerHTML={{
					__html: `
						const PIXEL_ID = document.currentScript.getAttribute("data-pixel-id");

						function initializeFacebookPixel(f, b, e, v, n, t, s) {
							if (f.fbq) return;
							n = f.fbq = function () {
								n.callMethod
									? n.callMethod.apply(n, arguments)
									: n.queue.push(arguments);
							};
							if (!f._fbq) f._fbq = n;
							n.push = n;
							n.loaded = !0;
							n.version = "2.0";
							n.queue = [];
							t = b.createElement(e);
							t.async = !0;
							t.src = v;
							s = b.getElementsByTagName(e)[0];
							s.parentNode.insertBefore(t, s);
						}

						initializeFacebookPixel(
							window,
							document,
							"script",
							"https://connect.facebook.net/en_US/fbevents.js"
						);

						window.fbq("init", PIXEL_ID);
					`
				}}
			/>
		</div>
	);
};

export default FacebookPixel;
