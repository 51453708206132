import { sendGTMEvent } from "@next/third-parties/google";

export const GTM_ID = {
	careers: "GTM-MVJPDFR",
	main: "GTM-WK9266J",
};

export const pageview = (url) => {
	sendGTMEvent({
		event: "pageview",
		page: url,
	});
};

export function gtag() {
	sendGTMEvent(arguments);
}

export const daysToMillis = (numberOfDays) => {
	return numberOfDays * 24 * 60 * 60 * 1000;
};

export const setCookie = (key, value, expiry = 7) => {
	const expires = new Date();
	expires.setTime(expires.getTime() + daysToMillis(expiry));
	document.cookie =
		key +
		"=" +
		encodeURIComponent(value) +
		";path=/" +
		";expires=" +
		encodeURIComponent(expires.toUTCString());
};
